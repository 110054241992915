import {
  GetUserListRequest,
  GetUserListResponse,
  useGetUerList,
} from '../../../../services/api/user-hook';
import UserListComponent, { UserListType } from './userListComponent';
import { useEffect, useState } from 'react';

import { ApiHookStatuses } from '../../../../interfaces';

const UserListContainer = () => {
  const [requestArgs, setRequestArgs] = useState<GetUserListRequest>({
    sort: 'true',
  });
  const { status, data, error, refetch } = useGetUerList(requestArgs);
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchError, setFetchError] = useState<any>();
  const [users, setUsers] = useState<UserListType[]>([]);

  useEffect(() => {
    if (status === ApiHookStatuses.loading && requestArgs.token === '') {
      setLoading(true);
    }
    if (status === ApiHookStatuses.error && error) {
      setLoading(false);
      setFetchError(error.message);
    }
    if (status === ApiHookStatuses.success && data) {
      setLoading(false);
      const uniqueUsers = [...data.users, ...users].filter(
        (user, index, self) =>
          index === self.findIndex((u) => u.userId === user.userId)
      );
      setUsers(uniqueUsers);
      if (data.token) {
        setRequestArgs({ token: data.token, sort: 'true' });
      }
    }
  }, [status, data]);

  useEffect(() => {
    refetch(requestArgs);
  }, [requestArgs]);

  return (
    <UserListComponent
      users={users}
      loading={loading}
      fetchError={fetchError}
    />
  );
};

export default UserListContainer;
